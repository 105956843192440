import { chain, compact, find, get, orderBy, uniqBy } from 'lodash';
import store from '@/store/index';
import { ref } from 'vue';
import axios from 'axios';
export function groupNews(newsData, loadedDataSet) {
  let updatedNews = [];
  chain(newsData)
    .groupBy('grouped_headline')
    .map((newsCollection, i) => {
      let existsInLoadedSet = compact(
        newsCollection.map(nc => find(updatedNews.concat(loadedDataSet), { grouped: [{ id: nc.id }] }))
      );
      if (!existsInLoadedSet.length) {
        if (get(find(newsCollection, 'grouped_headline'), 'grouped_headline') != null && newsCollection.length > 1) {
          return updatedNews.push({
            id: get(find(newsCollection, 'id'), 'id'),
            link_hash: get(find(newsCollection, 'link_hash'), 'link_hash'),
            grouped: newsCollection,
            headline: get(find(newsCollection, 'grouped_headline'), 'grouped_headline'),
            timestamp: get(find(newsCollection, 'timestamp'), 'timestamp'),
            similarNews: true,
            sources: uniqBy(newsCollection.map(source => [...source.sources]).flat(), 'id')
          });
        } else {
          return updatedNews.push(...newsCollection);
        }
      }
    })
    .value();
  return orderBy(updatedNews, 'timestamp', 'desc');
}
const onChainLoading = ref(false);
export async function showOnChainSidebar(link) {
  let url = new URL(link);
  let signalId = url.searchParams.get('id');
  onChainLoading.value = true;
  store.dispatch('enableOnchainSidebar', { metadata: null, onChainLoading: onChainLoading.value });
  const response = await axios.get('/data/onchain_signals/news', {
    params: {
      signal_id: signalId
    }
  });
  if (response.data?.signal?.length > 0) {
    onChainLoading.value = false;
    store.dispatch('enableOnchainSidebar', {
      rawMetadata: response.data.signal[0],
      onChainLoading: onChainLoading.value
    });
  }
}
export function signalNews(item) {
  if (item.similarNews) {
    item.grouped?.forEach(i => {
      return isSignalNews(i.link);
    });
  } else {
    return isSignalNews(item.link);
  }
}
const isSignalNews = link => {
  let url = new URL(link);
  let hosts = ['terminal.thetie.io', 'sigdev.thetie.io'];
  if (hosts.includes(url.host) && url.searchParams.get('id') != null) return true;
  else return false;
};
